import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Shimmer } from 'react-shimmer'; // Importar Shimmer desde react-shimmer

export const Products = () => {
    const [lineas, setLineas] = useState([]);
    const [loading, setLoading] = useState(true); // Estado para controlar la carga de datos

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('https://pinturaspromex.com/api2/web.php', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        action: 'consulta_lineas'
                    })
                });
                const data = await response.json();
                if (data.status) {
                    setLineas(data.lineas);
                } else {
                    console.error('Error: ', data.message);
                }
            } catch (error) {
                console.error('Error fetching data: ', error);
            } finally {
                setLoading(false); // Marca la carga como completa
            }
        };

        window.scrollTo(0, 0);
        fetchData();
    }, []);

    return (
        <div>
            <div className="banner-area bg-overlay" id="banner-area" style={{backgroundImage: "url(images/banner_products.png)", backgroundSize: "cover", backgroundPosition: "center", backgroundRepeat: "no-repeat"}}>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col">
                            <div className="banner-heading">
                                <h1 className="banner-title">
                                    Nuestros <span>Productos</span>
                                </h1>
                                <ol className="breadcrumb">
                                    <li>Pinturas Promex</li>
                                    <li><a href="#">Productos</a></li>
                                </ol>
                            </div> {/* Banner heading */}
                        </div>{/* Col end*/}
                    </div>{/* Row end*/}
                </div>{/* Container end*/}
            </div> {/* Banner area end*/}

            <section id="ts-intro" className="ts-intro pb-0 mb-5 mt-1">
                <div className="container">
                    <div className="row justify-content-center">
                        {loading ? (
                            Array.from({ length: 8 }).map((_, index) => (
                                <div key={index} className="col-6 col-lg-4 col-md-4" style={{ padding: "10px", borderRadius: "10px", overflow: "hidden" }}>
                                    <div style={{ borderRadius: "10px", overflow: "hidden" }}>
                                        <Shimmer width="100%" height="250px" />
                                    </div>
                                </div>
                            ))
                        ) : (
                            lineas.map((linea) => (
                                <div key={linea.id} className="col-12 col-md-6 col-lg-3" style={{ padding: "5px", display: "flex", flexDirection: "column" }}>
                                    <Link to={`/linea/${linea.id}`}>
                                        <div className="ts-intro-wrapper" style={{ flexGrow: 1 }}>
                                            <div className="intro-content text-center" style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", height: "100%" }}>
                                                <div style={{ width: "100%", height: "350px", position: "relative" }}>
                                                    <img src={linea.url_imagen} alt={linea.nombre_linea} style={{ position: "absolute", top: "0", left: "0", width: "100%", height: "100%", objectFit: "contain" }} />
                                                </div>
                                                <h2 className="mt-2">{linea.nombre_linea}</h2>
                                            </div>{/* Intro Content */}
                                            <i className="fa-solid fa-brush"></i>
                                        </div>{/* Intro Wrapper */}
                                    </Link>
                                </div> 
                            ))
                        )}
                    </div>
                </div> {/* Container End */}
            </section>
        </div>
    );
};

export default Products;
