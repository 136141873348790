import React, { useState, useEffect } from 'react';
import OwlCarousel from 'react-owl-carousel';
import { Link } from 'react-router-dom';

export const Promotions = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [promociones, setPromociones] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchPromociones = async () => {
            try {
                const response = await fetch('https://pinturaspromex.com/api2/web.php', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        action: 'promociones_web'
                    })
                });
                const data = await response.json();
                if (data.status) {
                    setPromociones(data.promociones);
                } else {
                    console.error('Error: ', data.message);
                }
            } catch (error) {
                console.error('Error fetching data: ', error);
            } finally {
                setLoading(false);
            }
        };

        window.scrollTo(0, 0);
        fetchPromociones();
    }, []);

    return (
        <div>
            <div className="banner-area bg-overlay" id="banner-area" style={{backgroundImage: "url(/images/banner_products.png)", backgroundSize: "cover", backgroundPosition: "center", backgroundRepeat: "no-repeat"}}>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col">
                            <div className="banner-heading">
                                <h1 className="banner-title">Nuestras <span>promociones</span></h1>
                                <ol className="breadcrumb">
                                    <li>Pinturas Promex</li>
                                    <li><a href="#">Promociones</a></li>
                                </ol>
                            </div> {/* Banner heading */}
                        </div>{/* Col end*/}
                    </div>{/* Row end*/}
                </div>{/* Container end*/}
            </div> {/* Banner area end*/}

            {loading ? (
                // Muestra un indicador de carga mientras se cargan los datos
                <div>Loading...</div>
            ) : promociones.length === 0 ? (
                // Si no hay promociones, muestra el mensaje
                <section className="mt-3 mb-5">
                    <div className="container">
                        <div className="row mt-5">
                            <div className="col-12 text-center">
                                <h2>Muy pronto</h2>
                                <p>Nuestras promociones estarán disponibles próximamente. Manténgase atento.</p>
                            </div>
                        </div>
                    </div>
                </section>
            ) : (
                // Si hay promociones, muestra el carrusel
                <div style={{ marginTop: '2rem', marginBottom: '2rem' }}>
                    <OwlCarousel
                        className="ts-slider-area owl-carousel"
                        items={1}
                        loop={true}
                        smartSpeed={500}
                        dots={false}
                        nav={true}
                        navText={['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>']}
                        autoplay={true}
                        mouseDrag={true}
                        responsive={{
                            0: {
                                nav: true,
                                mouseDrag: true,
                            },
                            600: {
                                nav: true,
                                mouseDrag: true,
                            },
                            1000: {
                                nav: true,
                                mouseDrag: true,
                            }
                        }}
                        style={{
                            width: '100vw',
                            margin: 0,
                            padding: 0
                        }}
                    >
                        {promociones.map((promocion, index) => (
                            <div
                                key={index}
                                className="slider-items slider-overlay"
                                style={{
                                    backgroundImage: `url(${promocion.fondo})`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                    backgroundRepeat: 'no-repeat',
                                    width: '100vw',
                                    height: '60vh',
                                    maxHeight: '500px',
                                    margin: 0,
                                    padding: 0
                                }}
                            >
                                <a
                                    href={promocion.action_url || undefined}
                                    target={promocion.action_url ? '_blank' : undefined}
                                    rel={promocion.action_url ? 'noopener noreferrer' : undefined}
                                    style={{ display: 'block', width: '100%', height: '100%' }}
                                >
                                    <img
                                        src={promocion.imagen_url}
                                        alt={promocion.titulo}
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            objectFit: 'cover',
                                        }}
                                    />
                                </a>
                            </div>
                        ))}
                    </OwlCarousel>
                </div>
            )}
        </div>
    );
}