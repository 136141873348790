import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

export const Catalogues = () => {

    const [categorias, setCategorias] = useState([]);

    useEffect(() => {
        async function fetchData() {
        try {
            const response = await fetch('https://pinturaspromex.com/api2/web.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    action: 'consultar_documentos'
                })
            });
            const data = await response.json();
            setCategorias(data.categorias);
        } catch (error) {
            console.error('Error al obtener datos:', error);
        }
        }

        window.scrollTo(0, 0);

        fetchData();
    }, []);

    function Documento({ documento }) {
        const cardStyle = {
          width: '18rem', // Ancho fijo para todas las tarjetas
          height: '20rem', // Alto fijo para todas las tarjetas
          color: 'white'
        };
      
        return (
          <div className="col-6 col-md-4 mb-5">
            <a href={documento.url_documento} target="_blank" style={{ textDecoration: 'none' }}>
              <div className="" style={cardStyle}>
                <div className="card-body d-flex flex-column justify-content-center align-items-center">
                  <img src={documento.url_banner} className="mt-3" height="300" width="300" alt="Icono" />
                  <h4 className="card-title text-center mt-3 mb-0">{documento.nombre}</h4>
                </div>
              </div>
            </a>
          </div>
        );
      }
      
      function Categoria({ categoria }) {
        return (
          <div id={categoria.id} className="container mb-3 mt-3">
            <div className="row justify-content-center align-items-center">
              <div className="col-12">
                <h1 className="mb-5">{categoria.nombre}</h1>
              </div>
              {categoria.documentos_array.map((documento) => (
                <Documento key={documento.id} documento={documento} />
              ))}
            </div>
          </div>
        );
      }

  return (
    <div>
      <div className="banner-area bg-overlay" id="banner-area" style={{backgroundImage: "url(/images/banner_products.png)", backgroundSize: "cover", backgroundPosition: "center", backgroundRepeat: "no-repeat"}}>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col">
                        <div className="banner-heading">
                            <h1 className="banner-title">Nuestros <span>Catálogos</span></h1>
                            <ol className="breadcrumb">
                                <li>Pinturas Promex</li>
                                <li><a href="#">Productos</a></li>
                            </ol>
                        </div> {/* Banner heading */}
                    </div>{/* Col end*/}
                </div>{/* Row end*/}
            </div>{/* Container end*/}
        </div> {/* Banner area end*/}

        <section class="mt-3 mb-5">
		   <div class="container">
                <div className="row mt-5">
                    {categorias.map((categoria) => (
                        <Categoria key={categoria.id} categoria={categoria} />
                    ))}
                </div>
            </div>
        </section>
    </div>
  )
}
