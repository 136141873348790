import React from 'react'
import OwlCarousel from 'react-owl-carousel';

import './Tips.css';

export const Tips = () => {

    const clickTip = (tip) => {
        document.getElementById("tip1").style.display = "none";
        document.getElementById("tip2").style.display = "none";
        document.getElementById("tip3").style.display = "none";
        document.getElementById("tip4").style.display = "none";
        document.getElementById("tip5").style.display = "none";

        document.getElementById("tip" + tip).style.display = "block";

        window.scrollTo({
            top: document.getElementById("tip" + tip).offsetTop,
            behavior: "smooth"
        });
    };

    return (
        <div>
            <div className="banner-area bg-overlay" id="banner-area" style={{backgroundImage: 'url(images/banner_tips.png)', backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat'}}>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col">
                            <div className="banner-heading">
                                <h1 className="banner-title"><img src="images/texto_tips.webp" alt="Banner Title" /></h1>
                                <ol className="breadcrumb">
                                    <li>Pinturas Promex</li>
                                    <li><a href="#">Tips</a></li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <section id="ts-intro" className="ts-intro pb-0 mb-5">
                <div className="container">
                    <div className="row no-gutters">
                        <div className="col-lg-3 col-md-12" style={{ cursor: 'pointer' }}>
                            <div className="ts-intro-wrapper">
                                <div className="intro-content text-center" onClick={() => clickTip(1)}>
                                    <img src="/images/leon_tip1.webp" style={{ textAlign: 'center' }} height="200" alt="Tip 1"/>
                                    <h2 className="mt-2">¿Cuánta pintura necesito?</h2>
                                </div>
                                <i className="fa-solid fa-question"></i>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-12" style={{ cursor: 'pointer' }}>
                            <div className="ts-intro-wrapper highlight">
                                <div className="intro-content text-center" onClick={() => clickTip(2)}>
                                    <img src="/images/leon_tip2.webp" style={{ textAlign: 'center' }} height="200" alt="Tip 2"/>
                                    <h2 className="mt-2">¿Qué tipo de pintura necesito?</h2>
                                </div>
                                <i className="fa-solid fa-bottle-water"></i>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-12" style={{ cursor: 'pointer' }}>
                            <div className="ts-intro-wrapper intro-carousel">
                                <div className="intro-content text-center" onClick={() => clickTip(3)}>
                                    <img src="/images/leon_tip3.webp" style={{ textAlign: 'center' }} height="200" alt="Tip 3"/>
                                    <h2 className="mt-2">¿Cuánto rebajar la pintura?</h2>
                                </div>
                                <i className="fa-solid fa-ruler"></i>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-12" style={{ cursor: 'pointer' }}>
                            <div className="ts-intro-wrapper highlight">
                                <div className="intro-content text-center" onClick={() => clickTip(4)}>
                                    <img src="/images/leon_tip4.webp" style={{ textAlign: 'center' }} height="200" alt="Tip 4"/>
                                    <h2 className="mt-2">Antes de pintar</h2>
                                </div>
                                <i className="fa-solid fa-brush"></i>
                            </div>
                        </div>
                        <div className="col-lg-12 col-md-12 mt-5" style={{ cursor: 'pointer' }}>
                            <div className="ts-intro-wrapper highlight">
                                <div className="intro-content text-center" onClick={() => clickTip(5)}>
                                    <img src="/images/paintdilla.webp" style={{ textAlign: 'center' }} height="200" alt="La Paintdilla"/>
                                    <h2 className="mt-2">Guía "La Paintdilla"</h2>
                                </div>
                                <i className="fa-solid fa-brush"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="tip1" className="main-container pb-5" style={{ display: 'none' }}>
                <div className="ts-about-us">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <h2 className="section-title">
                                    ¿Cuánta pintura necesito?
                                </h2>
                            </div>
                        </div>
                        <div className="row overflow-hidden no-gutters">
                            <div className="col-lg-3 col-md-12">
                                <img className="img-fluid" src="images/leon_tip2_grande.webp" alt="Tip 1" />
                            </div>
                            <div className="col-lg-9 col-md-12">
                                <p style={{ fontSize: '22px' }}>Calcule la cantidad de pintura que va a utilizar siguiendo los siguientes pasos:</p>
                                
                                <table style={{
                                    color: 'black',
                                    borderRadius: '10px',
                                    borderSpacing: 0,
                                    borderCollapse: 'collapse',
                                    width: '90%',
                                    boxShadow: '0 0 20px rgba(0, 0, 0, 0.1)',
                                    border: 0,
                                    margin: '10px'
                                }}>
                                    <tbody>
                                        <tr>
                                            <td style={{ padding: '10px', borderTopLeftRadius: '10px' }}>
                                                Mida el alto y largo de las pared o techos que va a pintar con un mismo color. Multiplique estos números, obteniendo el área.
                                            </td>
                                            <td style={{ padding: '10px' }}>
                                                Muro 1 (A) 2.5 m x (L )4 m = 10
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ padding: '10px' }}>
                                                Mida el alto y largo de las ventanas y puertas existentes en el muro en cuestión. Multiplique los pares de medidas (alto y largo de cada elemento) y sume los resultados
                                            </td>
                                            <td style={{ padding: '10px' }}>
                                                Puerta 1: (A) 0.9m x 2 m = 1.8 Ventana 1: (A) 1.5m x 2 m = 3.0 Suma: 4.8
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ padding: '10px' }}>
                                                Al área total del muro reste el resultado obtenido en el paso anterior.
                                            </td>
                                            <td style={{ padding: '10px' }}>
                                                10 - 4.8 = 5.2
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ padding: '10px' }}>
                                                Al área total del muro reste el resultado obtenido en el paso anterior.
                                            </td>
                                            <td style={{ padding: '10px' }}>
                                                Muro 1 + Muro 2 + Muro 3 + Muro 4 +Techo 5.2 + 6.5 + 8 + 10 + 7.5 = 37.2 m2
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ padding: '10px' }}>
                                                Considerando la superficie a pintar, defina cuántas manos deberá dar de pintura para lograr los mejores resultados.
                                            </td>
                                            <td style={{ padding: '10px' }}>
                                                2 Manos
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ padding: '10px' }}>
                                                Multiplique el resultado de la resta por el número de manos
                                            </td>
                                            <td style={{ padding: '10px' }}>
                                                37.2 x 2 = 74.4
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ padding: '10px' }}>
                                                Divida esta cantidad entre el rendimiento promedio de la pintura a usar. Por ejemplo: Un galón de pintura de base átex cubrirá 35 m2
                                            </td>
                                            <td style={{ padding: '10px' }}>
                                                74.4/35 = 2.15
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ padding: '10px', borderBottomLeftRadius: '10px' }}>
                                                Ahora sí, compre los galones de pinturas de acuerdo al resultado obtenido, redondeando al siguiente número entero.
                                            </td>
                                            <td style={{ padding: '10px', borderBottomRightRadius: '10px' }}>
                                                Usted requiere 3 galones de pintura
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="tip2" className="main-container pb-5" style={{ display: 'none' }}>
                <div className="ts-about-us">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <h2 className="section-title">
                                    ¿Qué tipo de pintura necesito?
                                </h2>
                            </div>
                        </div>
                        <div className="row overflow-hidden no-gutters">
                            <div className="col-lg-4 col-md-12">
                                <img className="img-fluid" src="images/leon_tip2_grande.webp" height="100" alt="Tip 2" />
                            </div>
                            <div className="col-lg-8 col-md-12">
                                <p style={{ fontSize: '22px', textAlign: 'justify' }}>

                                    Seleccione la pintura teniendo en mente siempre el uso requerido:<br/><br/>
                                    • Para muros exteriores, utilice pinturas con base acrílica<br/>
                                    • Para muros interiores, prefiera pinturas con base vinílica<br/><br/>

                                    <b>La pintura Vinílica es ideal para:</b><br/><br/>

                                    • Pintar Interiores y Exteriores.<br/>
                                    • Es base agua, no contamina, no tiene plomo, ni expide olores, es eco-friendy.<br/>
                                    • Su secado es de 1 hora aproximadamente.<br/>
                                    • Ideal para dormitorios, oficinas, comedores y áreas de uso común.<br/>
                                    • Se encuentran en acabado mate, brillante y satinado.<br/>
                                    • Se recomienda usar sellador vinílico  7x1 Promex para un mejor acabado.<br/> 
                                    • Usar brocha o rodillo para dar un mejor acabado.<br/>

                                    <br/>

                                    <b>La pintura de Esmalte es ideal para:</b><br/><br/>
                                    • Pintar a Exteriores.<br/>
                                    • Son lavables y resistentes a la corrosión.<br/>
                                    • Es pintura base aceite.<br/>
                                    • Ideal para estructuras de herrería, madera, cocinas y baños.<br/>
                                    • Su secado es de 15 a 30 minutos aproximadamente.<br/>
                                    • Se encuentran en acabados brillantes, semimate y mate.<br/>
                                    • Usar pistola de aire, brocha o rodillo.<br/>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="tip3" className="main-container pb-5" style={{ display: 'none' }}>
                <div className="ts-about-us">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <h2 className="section-title">
                                    ¿Cuánto rebajar la pintura?
                                </h2>
                            </div>
                        </div>
                        <div className="row overflow-hidden no-gutters">
                            <div className="col-lg-7 col-md-12">
                                <div className="box-skew-hidden-left">
                                    <div className="box-skew-left">
                                        <img className="img-fluid" src="images/leon_tip3_grande.webp" alt="Tip 3" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5 col-md-12">
                                <div className="box-skew-right">
                                    <div className="box-content-wrapper">
                                        <p style={{ fontSize: '22px' }}>No reduzca la pintura más de lo recomendado en el envase, ya que disminuye el poder cubriente de la misma y origina chorreaduras. Por ejemplo, 1/8 lt de agua es suficiente para diluir 4 litros de una pintura a base de látex.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="tip4" className="main-container pb-5" style={{ display: 'none' }}>
                <div className="ts-about-us">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <h2 className="section-title">
                                    Antes de pintar
                                </h2>
                            </div>
                        </div>
                        <div className="row overflow-hidden no-gutters">
                            <div className="col-lg-4 col-md-12">
                                <img className="img-fluid" src="images/leon_tip4_grande.webp" height="100" alt="Tip 4" />
                            </div>
                            <div className="col-lg-8 col-md-12">
                                <p style={{ fontSize: '22px' }}>
                                    • Algunos accesorios que se requieren antes de pintar son: Franela, Masking Tape, Espátula o Lija.<br/><br/>
                                    • En paredes lisas: Cuando vaya a pintar, verifique que la superficie esté libre de polvo, grasa y pintura suelta.<br/><br/>
                                    • Nunca lave con jabón ni detergente, utilice espuma de jabón y una esponja, retire la espuma con una franela humedecida.<br/><br/>
                                    • En superficies de yeso nuevo, sellar con Sellador Vinílico 7x1 Promex y pintar luego de un periodo de 60 a 90 días después de aplicado el yeso.<br/><br/>
                                    • En superficies de metal elimine el óxido y la grasa. Si no la elimina podrá ocasionar que la pintura no se adhiera.<br/><br/>
                                    • Utilice lija para remover las imperfecciones, ya sea manualmente o con un medio mecanico.<br/><br/>
                                    • En superficies de madera: Lije la superficie en dirección a la vena, usando una lija de grano grueso y vaya cambiando a una lija más fina, hasta que la superficie este lisa y tersa.<br/><br/>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="tip5" className="main-container mb-5" style={{ display: 'none' }}>

                <OwlCarousel
                    className="owl-theme ts-slider-area mt-5 mb-5"
                    items={1}
                    loop={true}
                    smartSpeed={900}
                    dots={false}
                    nav={true}
                    navText={['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>']}
                    autoplay={false}
                    mouseDrag={true}
                    responsive={{
                    0: {
                        nav: false,
                        mouseDrag: false,
                    },
                    600: {
                        nav: false,
                        mouseDrag: false,
                    },
                    1000: {
                        nav: true,
                        mouseDrag: true,
                    }
                    }}
                >
                        
                    <div className="slider-items slider-items-tips slider-overlay" style={{ background: 'url(images/fondo_paintdilla.jpg)' }}>
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-lg-12 col-md-12">
                                    <div className="slider-content">
                                        <div className="row">
                                            <div className="col-12">
                                                <img className="img-fluid mt-5 mb-5" src="images/paintdilla1.webp" alt="Tip 5" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="slider-items slider-items-tips slider-overlay" style={{ background: 'url(images/fondo_paintdilla.jpg)' }}>
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-lg-12 col-md-12">
                                    <div className="slider-content">
                                        <div className="row">
                                            <div className="col-12">
                                                <img className="img-fluid mt-5 mb-5" src="images/paintdilla2.webp" alt="Tip 5" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="slider-items slider-items-tips slider-overlay" style={{ background: 'url(images/fondo_paintdilla.jpg)' }}>
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-lg-12 col-md-12">
                                    <div className="slider-content">
                                        <div className="row">
                                            <div className="col-12">
                                                <img className="img-fluid mt-5 mb-5" src="images/paintdilla3.webp" alt="Tip 5" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="slider-items slider-items-tips slider-overlay" style={{ background: 'url(images/fondo_paintdilla.jpg)' }}>
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-lg-12 col-md-12">
                                    <div className="slider-content">
                                        <div className="row">
                                            <div className="col-12">
                                                <img className="img-fluid mt-5 mb-5" src="images/paintdilla4.webp" alt="Tip 5" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="slider-items slider-items-tips slider-overlay" style={{ background: 'url(images/fondo_paintdilla.jpg)' }}>
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-lg-12 col-md-12">
                                    <div className="slider-content">
                                        <div className="row">
                                            <div className="col-12">
                                                <img className="img-fluid mt-5 mb-5" src="images/paintdilla5.webp" alt="Tip 5" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="slider-items slider-items-tips slider-overlay" style={{ background: 'url(images/fondo_paintdilla.jpg)' }}>
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-lg-12 col-md-12">
                                    <div className="slider-content">
                                        <div className="row">
                                            <div className="col-12">
                                                <img className="img-fluid mt-5 mb-5" src="images/paintdilla6.webp" alt="Tip 5" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="slider-items slider-items-tips slider-overlay" style={{ background: 'url(images/fondo_paintdilla.jpg)' }}>
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-lg-12 col-md-12">
                                    <div className="slider-content">
                                        <div className="row">
                                            <div className="col-12">
                                                <img className="img-fluid mt-5 mb-5" src="images/paintdilla7.webp" alt="Tip 5" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="slider-items slider-items-tips slider-overlay" style={{ background: 'url(images/fondo_paintdilla.jpg)' }}>
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-lg-12 col-md-12">
                                    <div className="slider-content">
                                        <div className="row">
                                            <div className="col-12">
                                                <img className="img-fluid mt-5 mb-5" src="images/paintdilla8.webp" alt="Tip 5" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="slider-items slider-items-tips slider-overlay" style={{ background: 'url(images/fondo_paintdilla.jpg)' }}>
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-lg-12 col-md-12">
                                    <div className="slider-content">
                                        <div className="row">
                                            <div className="col-12">
                                                <img className="img-fluid mt-5 mb-5" src="images/paintdilla9.webp" alt="Tip 5" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="slider-items slider-items-tips slider-overlay" style={{ background: 'url(images/fondo_paintdilla.jpg)' }}>
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-lg-12 col-md-12">
                                    <div className="slider-content">
                                        <div className="row">
                                            <div className="col-12">
                                                <img className="img-fluid mt-5 mb-5" src="images/paintdilla10.webp" alt="Tip 5" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="slider-items slider-items-tips slider-overlay" style={{ background: 'url(images/fondo_paintdilla.jpg)' }}>
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-lg-12 col-md-12">
                                    <div className="slider-content">
                                        <div className="row">
                                            <div className="col-12">
                                                <img className="img-fluid mt-5 mb-5" src="images/paintdilla11.webp" alt="Tip 5" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="slider-items slider-items-tips slider-overlay" style={{ background: 'url(images/fondo_paintdilla.jpg)' }}>
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-lg-12 col-md-12">
                                    <div className="slider-content">
                                        <div className="row">
                                            <div className="col-12">
                                                <img className="img-fluid mt-5 mb-5" src="images/paintdilla12.webp" alt="Tip 5" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="slider-items slider-items-tips slider-overlay" style={{ background: 'url(images/fondo_paintdilla.jpg)' }}>
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-lg-12 col-md-12">
                                    <div className="slider-content">
                                        <div className="row">
                                            <div className="col-12">
                                                <img className="img-fluid mt-5 mb-5" src="images/paintdilla13.webp" alt="Tip 5" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="slider-items slider-items-tips slider-overlay" style={{ background: 'url(images/fondo_paintdilla.jpg)' }}>
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-lg-12 col-md-12">
                                    <div className="slider-content">
                                        <div className="row">
                                            <div className="col-12">
                                                <img className="img-fluid mt-5 mb-5" src="images/paintdilla14.webp" alt="Tip 5" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="slider-items slider-items-tips slider-overlay" style={{ background: 'url(images/fondo_paintdilla.jpg)' }}>
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-lg-12 col-md-12">
                                    <div className="slider-content">
                                        <div className="row">
                                            <div className="col-12">
                                                <img className="img-fluid mt-5 mb-5" src="images/paintdilla15.webp" alt="Tip 5" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </OwlCarousel>
                {/* Add more slider items for additional tips */}
            </section>
        </div>
    );
}
