import React from 'react'
import { Link } from 'react-router-dom';

export const Footer = () => {
  return (
    <div>
        <footer className="footer" id="footer">
                <div className="footer-main">
                    <div className="p-5">
                        <h1 style={{color: 'white', textAlign: 'center', fontWeight: 'bold'}}>CONTACTO</h1>
                        <div className="row mt-4">
                            <div className="col-lg-2 col-md-6 footer-widget footer-about text-center">
                                <div className="footer-logo">
                                    <a href="index">
                                        <img className="img-fluid text-center" src="/images/logo_footer.png" style={{height: '180px'}} alt=""></img>
                                    </a>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6 footer-widget widget-service">
                                <h3 className="widget-title">Cd. México</h3>
                                <ul className="unstyled">
                                    <li style={{color: 'white'}}><i className="fa fa-map-marker" style={{fontSize: '24px', color: 'white', marginRight: '10px'}}></i> <a href="https://maps.app.goo.gl/CeAxt5A8EgjGNW3y8" target="_blank">Galeana 101. La Loma. Tlalnepantla, Edo de Mex. C.P. 54060</a></li>
                                    <li style={{color: 'white'}}><i className="fa fa-envelope-o" style={{fontSize: '24px', color: 'white', marginRight: '10px'}}></i> <a href="mailto:contacto@pinturaspromex.com.mx">contacto@pinturaspromex.com.mx</a></li>
                                    <li style={{color: 'white'}}><i className="fa fa-phone" style={{fontSize: '24px', color: 'white', marginRight: '10px'}}></i> <a href="tel:5553616917">55 5361 6917</a></li>
                                    <li style={{color: 'white'}}><i className="fa fa-phone" style={{fontSize: '24px', color: 'white', marginRight: '10px'}}></i> <a href="tel:5555652661">55 5565 2661</a></li>
                                </ul>
                            </div>


                            <div className="col-lg-3 col-md-6 footer-widget widget-service">
                                <h3 className="widget-title">Chihuahua</h3>
                                <ul className="unstyled">
                                    <li style={{color: 'white'}}><i className="fa fa-map-marker" style={{fontSize: '24px', color: 'white', marginRight: '10px'}}></i> <a href="https://maps.app.goo.gl/i9cf8mpSWzSVq7KB8" target="_blank">Calle Pino #3114, Col. Zona Industrial Nombre de Dios</a></li>
                                    <li style={{color: 'white'}}><i className="fa fa-envelope-o" style={{fontSize: '24px', color: 'white', marginRight: '10px'}}></i> <a href="mailto:chihuahua@pinturaspromex.com.mx">chihuahua@pinturaspromex.com.mx</a></li>
                                    <li style={{color: 'white'}}><i className="fa fa-phone" style={{fontSize: '24px', color: 'white', marginRight: '10px'}}></i> <a href="tel:6144179477">(614) 417 9477</a></li>
                                    <li style={{color: 'white'}}><i className="fa fa-phone" style={{fontSize: '24px', color: 'white', marginRight: '10px'}}></i> <a href="tel:6144199961">(614) 419 9961</a></li>
                                </ul>
                            </div>
                            
                            <div className="col-lg-3 col-md-6 footer-widget widget-service">
                                <h3 className="widget-title">Ciudad Juárez</h3>
                                <ul className="unstyled">
                                    <li style={{color: 'white'}}><i className="fa fa-map-marker" style={{fontSize: '24px', color: 'white', marginRight: '10px'}}></i> <a href="https://maps.app.goo.gl/1u128VCnhtZPBXey6" target="_blank">Eje Vial Juan Gabriel #4240-interior 3, El Vergel</a></li>
                                    <li style={{color: 'white'}}><i className="fa fa-envelope-o" style={{fontSize: '24px', color: 'white', marginRight: '10px'}}></i> <a href="mailto:juarez@pinturaspromex.com.mx">juarez@pinturaspromex.com.mx</a></li>
                                    <li style={{color: 'white'}}><i className="fa fa-phone" style={{fontSize: '24px', color: 'white', marginRight: '10px'}}></i> <a href="tel:6566107058">(656) 610 7058</a></li>
                                </ul>
                            </div>
                            
                        </div>
                    </div>
                </div>
                <div className="copyright">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-5 col-md-12">
                                <div className="copyright-info"><span>Copyright © <script>document.write(new Date().getFullYear());</script> Pinturas Promex del Norte.</span>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-12">
                                <div className="copyright-info">
                                    <a href="https://www.facebook.com/pinturaspromexnorte/" target="_blank"><i className="fa fa-facebook" style={{fontSize: '24px', color: 'white', marginLeft: '10px'}}></i></a>
                                    <a href="https://www.instagram.com/pinturaspromex_cuu?igsh=cHI2cjhncXQ1Zzkw&utm_source=qr" target="_blank"><i className="fa fa-instagram" style={{fontSize: '24px', color: 'white', marginLeft: '10px'}}></i></a>
                                    <a href="https://x.com/PINTUPROMEX?s=08" target="_blank"><i className="fa fa-brands fa-x-twitter" style={{fontSize: '24px', color: 'white', marginLeft: '10px'}}></i></a>
                                    <a href="https://www.pinterest.com.mx/pinturaspromex/" target="_blank"><i className="fa-brands fa-pinterest" style={{fontSize: '24px', color: 'white', marginLeft: '10px'}}></i></a>
                                </div>
                            </div>
                            <div className="col-lg-5 col-md-12">
                                <div className="footer-menu">
                                    <ul className="nav unstyled">
                                         {/* <li><Link to="/">Inicio</Link></li> */}
                                        <li><Link to="products">Productos</Link></li>
                                        {/* <li><Link to="about">Nosotros</Link></li> */}
                                        <li><a href="/branches" target="_blank">Sucursales</a></li>
                                        <li><Link to="aviso-privacidad">Aviso de Privacidad</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="back-to-top" id="back-to-top" data-spy="affix" data-offset-top="10" style={{display: 'block'}}>
                        <button className="back-btn" title="Back to Top">
                            <i className="fa fa-angle-double-up"></i>
                        </button>
                    </div>
                </div>
          </footer>
    </div>
  )
}
