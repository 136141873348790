import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Table } from 'react-bootstrap';
import { Shimmer } from 'react-shimmer'; // Importa Shimmer desde react-shimmer
import './Marca.css';

export const Marca = () => {
  const { id } = useParams();
  const [marca, setMarca] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://pinturaspromex.com/api2/web.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            action: 'consulta_marca',
            marca: id
          })
        });
        const data = await response.json();
        if (data.status) {
          setMarca(data.marca);
        } else {
          console.error('Error: ', data.message);
        }
      } catch (error) {
        console.error('Error fetching data: ', error);
      } finally {
        setLoading(false); // Marca la carga como completa
      }
    };

    window.scrollTo(0, 0);
    fetchData();
  }, [id]);

  return (
    <div>
      <div className="banner-area bg-overlay" id="banner-area" style={{backgroundImage: "url(/images/banner_products.png)", backgroundSize: "cover", backgroundPosition: "center", backgroundRepeat: "no-repeat"}}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col">
              <div className="banner-heading">
                <h1 className="banner-title"><span>Marca</span> {loading ? <Shimmer width={120} height={20} /> : marca.nombre_marca}</h1>
                <ol className="breadcrumb">
                  <li>Pinturas Promex</li>
                  <li><a href="#">Productos</a></li>
                </ol>
              </div> {/* Banner heading */}
            </div>{/* Col end*/}
          </div>{/* Row end*/}
        </div>{/* Container end*/}
      </div> {/* Banner area end*/}

      <section id="ts-intro" className="ts-intro pb-0 mb-5 mt-1">
        <div className="container">
          <div className="row no-gutters justify-content-center">

            <div className="col-12 col-lg-6 col-md-6 text-center">
              {loading ? (
                <div style={{ width: "100%", height: "400px", position: "relative" }}>
                  <Shimmer width="100%" height="100%" />
                </div>
              ) : (
                <img src={marca.url_imagen} height="400" style={{textAlign: "center"}} alt={marca.nombre_marca} />
              )}
              <h1>{loading ? <Shimmer width="80%" height={20} /> : marca.nombre_marca}</h1>
            </div>

            <div className="col-12 col-lg-6 col-md-6">
              <Table striped bordered hover className="table-purple">
                <thead>
                  <tr>
                    <th>CARACTERÍSTICA</th>
                    <th>DESCRIPCIÓN</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Características</td>
                    <td>{loading ? <Shimmer width="100%" height={20} /> : marca.caracteristicas}</td>
                  </tr>
                  <tr>
                    <td>Acabado</td>
                    <td>{loading ? <Shimmer width="100%" height={20} /> : marca.acabado}</td>
                  </tr>
                  <tr>
                    <td>Rendimiento</td>
                    <td>{loading ? <Shimmer width="100%" height={20} /> : marca.rendimiento}</td>
                  </tr>
                  <tr>
                    <td>Superficies</td>
                    <td>{loading ? <Shimmer width="100%" height={20} /> : marca.superficies}</td>
                  </tr>
                  <tr>
                    <td>Recomendación</td>
                    <td>{loading ? <Shimmer width="100%" height={20} /> : marca.recomendaciones}</td>
                  </tr>
                  <tr>
                    <td>Aplicación</td>
                    <td>{loading ? <Shimmer width="100%" height={20} /> : marca.aplicaciones}</td>
                  </tr>
                  <tr>
                    <td>Secado</td>
                    <td>{loading ? <Shimmer width="100%" height={20} /> : marca.secado}</td>
                  </tr>
                  <tr>
                    <td>Presentación</td>
                    <td>{loading ? <Shimmer width="100%" height={20} /> : marca.presentaciones}</td>
                  </tr>
                  <tr>
                    <td>Eco-Friendly</td>
                    <td>{loading ? <Shimmer width="100%" height={20} /> : marca.ecofriendly}</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
          {/* Main Row End */}
        </div>
        {/* Container End */}
      </section>
      {/* Intro End */}
    </div>
  )
}

export default Marca;
